import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Stack from 'react-bootstrap/Stack';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

import './index.css';

function Square(props) {
  return (
    <button className="square" onClick={props.onClick} style={{backgroundColor: props.winningSquares ? '#ccc': ''}}>
      {props.value}
    </button>
  );
}

class Board extends React.Component {
	renderSquare(i) {
		let winningSquare = this.props.winningSquares && this.props.winningSquares.includes(i) ? true : false;
		return (
			<Square
				value={this.props.squares[i]}
				onClick={() => this.props.onClick(i)}
				key={i}
				winningSquares={winningSquare}
			/>
		);
	}

	renderRow(row){
		const squares = [];
		const offset = row * this.props.totalCols;
		for(let x = 0; x < this.props.totalCols; x++){
			squares.push(
				this.renderSquare(x + offset)
			);
		}
		return (
			<div key={row} className="board-row">{squares}</div>
		);
	}
	
	render() {
		const rows = [];
		for(let y = 0; y < this.props.totalRows; y++){
			rows.push(
				this.renderRow(y)
			);
		}
		return (
			<div>{rows}</div>
		);
	}
}

function History(props) {
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	
	return (
		<>
			<Button onClick={handleShow}>Show Move History</Button>
			<Offcanvas show={show} onHide={handleClose}>
				<Offcanvas.Header closeButton>
					<Offcanvas.Title>Move History</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<ListGroup>{props.moves}</ListGroup>
				</Offcanvas.Body>
			</Offcanvas>
		</>
	);
}

class NewGame extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			cols: '',
			rows: '',
			win: '' ,
			connectFour: false,
			alert: null
		};
	}
	
	handleChange = (event) => {
		this.setState({
			[event.target.name]: parseInt(event.target.value)
		})
	}
	
	handleSwitch = (event) => {
		this.setState({
			[event.target.name]: !this.state.connectFour
		})
	}
	
	handleSubmit = (event) => {
		event.preventDefault();
		if(this.state.win > this.state.cols && this.state.win > this.state.rows){
			this.setState({
				alert: "The winning amount cannot be greater than both the number of columns and the number of rows."
			})
		} else {
			this.props.handleNewGame(this.state.rows,this.state.cols,this.state.win,this.state.connectFour);
			this.props.handleClose();
		}
	}
	
	render(){
		const alert = () => {
			if(this.state.alert){
				return (
					<Alert 
						variant="danger" 
						onClose={() => this.setState({
							alert: null
						})}
						dismissible
					>
						<Alert.Heading>Invalid Game State</Alert.Heading>
						<p>{this.state.alert}</p>
					</Alert>
				);
			}
		}
		return (
			<>
				{alert()}
				<Form onSubmit={this.handleSubmit}>
					<Form.Group className="mb-3" controlId="cols">
						<Form.Label>Columns:</Form.Label>
						<Form.Control required type="number" name="cols" value={this.state.cols} onChange={this.handleChange} min="1" />
					</Form.Group>
					<Form.Group className="mb-3" controlId="rows">
						<Form.Label>Rows:</Form.Label>
						<Form.Control required type="number" name="rows" value={this.state.rows} onChange={this.handleChange} min="1" />
					</Form.Group>
					<Form.Group className="mb-3" controlId="win">
						<Form.Label>Winning Amount:</Form.Label>
						<Form.Control required type="number" name="win" value={this.state.win} onChange={this.handleChange} min="1" />
					</Form.Group>
					<Form.Group className="mb-3" controlId="connectFour">
						<Form.Switch
							id="connectFour"
							name="connectFour"
							label="Enable Connect4"
							onChange={this.handleSwitch}
						>
						</Form.Switch>
						
					</Form.Group>
					<Button variant="primary" type="submit">
						New Game
					</Button>
				</Form>
			</>
		);
	}
}

function Menu(props) {
	const [show,setShow] = useState(true);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	
	return (
		<>
			<Button onClick={handleShow}>New Game</Button>
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>New Game</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<NewGame
						handleNewGame={(newRows,newCols,newWin,connectFour) => props.handleNewGame(newRows,newCols,newWin,connectFour)}
						handleClose={handleClose}
					></NewGame>					
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

class Game extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      history: [
        {
          squares: Array(42).fill(null),
		  location: 0
        }
      ],
	  totalRows: 6,
	  totalCols: 7,
	  winAmount: 4,
      stepNumber: 0,
      xIsNext: true,
	  connectFour: true
    };
  }

	handleNewGame(newRows,newCols,newWin,connectFour) {
		const newGrid = newRows * newCols;
		this.setState({
			history: [
				{
					squares: Array(newGrid).fill(null),
					location: 0
				}
			],
			totalRows: newRows,
			totalCols: newCols,
			winAmount: newWin,
			stepNumber: 0,
			xIsNext: true,
			connectFour: connectFour
		});
	}

  handleClick(i) {
    const history = this.state.history.slice(0, this.state.stepNumber + 1);
    const current = history[history.length - 1];
    const squares = current.squares.slice();
	const location = current.location;
    if (calculateWinner(location,squares,this.state.winAmount,this.state.totalCols) || squares[i]) {
      return;
    }
	
	// CONNECT4 SETTING
	if(this.state.connectFour){
		if (squares[i+this.state.totalCols] === null) {
			this.handleClick(i+this.state.totalCols);
			return;
		}
	}
	
    squares[i] = this.state.xIsNext ? "X" : "O";
    this.setState({
      history: history.concat([
        {
          squares: squares,
		  location: i
        }
      ]),
      stepNumber: history.length,
      xIsNext: !this.state.xIsNext
    });
  }
  
  jumpTo(step) {
    this.setState({
      stepNumber: step,
      xIsNext: (step % 2) === 0
    });
  }

  render() {
    const history = this.state.history;
    const current = history[this.state.stepNumber];
    const winner = calculateWinner(current.location,current.squares,this.state.winAmount,this.state.totalCols);
	
	const moves = history.map((step, move) => {
		const location = calculateLocation(step.location,this.state.totalCols);
		const desc = move ?
			'Go to move #' + move + ' ' + location:
			'Go to game start';
		if(this.state.stepNumber === move){
			return (
				<ListGroup.Item key={move} onClick={() => this.jumpTo(move)} action active disabled>{desc}</ListGroup.Item>
			);
		} else {
			return (
				<ListGroup.Item key={move} onClick={() => this.jumpTo(move)} action>{desc}</ListGroup.Item>
			);
		}
	});

    let status;
    if (winner) {
      status = "Winner: " + winner.winner;
    } else {
      status = "Next player: " + (this.state.xIsNext ? "X" : "O");
    }

	return (
		<Stack gap={4}>
			<Navbar bg="dark" variant="dark" sticky="top">
				<Container>
					<Navbar.Brand>Game App</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="justify-content-end flex-grow-1 pe-3">
							<Menu 
								handleNewGame={(newRows,newCols,newWin,connectFour) => this.handleNewGame(newRows,newCols,newWin,connectFour)}
							/>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
			<Stack gap={3}>
				<Container fluid="md">
					<Row className="justify-content-center">
						<Col xs="auto">
							<div><h2>{status}</h2></div>
						</Col>
					</Row>
				</Container>
				<Container fluid="md">
					<Row className="justify-content-center">
						<Col className="game-board" xs="auto">
							<Board
								squares={current.squares}
								onClick={i => this.handleClick(i)}
								totalRows={this.state.totalRows}
								totalCols={this.state.totalCols}
								winningSquares={winner && winner.winningSquares}
							/>
						</Col>
					</Row>
				</Container>
				<Container fluid="md">
					<Row className="justify-content-center">
						<Col xs="auto">
							<History
								moves={moves}
							/>
						</Col>
					</Row>
				</Container>
			</Stack>
		</Stack>
    );
  }
}

// ========================================

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Game />);

function calculateWinner(location,squares,winAmount,totalCols){
	const index = location;
	const col = calculateCol(index,totalCols);
	const row = calculateRow(index,totalCols);
	// The total number of possible win states on each axis is equal to the amount of squares required to win. Thus, we need to check in each direction of the current square, up to the number of possible win states minus one.
	const winCheck = winAmount - 1;
	var winningSquares = [];
	
	// We have 2 loops to check for all possible win states per axis. The first loop sets the starting point of where to check during the second loop. 
	//For example, if we need to check for 3 win states, X-2 to X, X-1 to X+1, X to X+2, we set our recursive i variable to be 2 (3 win states minus one). Then, we set the top value we need to check to equal to i minus the winCheck, which will be 0 in this example. On our second loop through, i will be 1, which means our loop check will be -1. Like this, we can check the full range of each win state. 
	// The second loop is equal to i, which means that we start at X - 2 (if there are 3 win states), if X-2 to X doesn't contain a winning state, then we break out of this loop. Then i wil then be 1, which means the second loop starts at X-1 and goes to X+1. 
	// For any of these win state checks that check along the X axis, we need to put in a preliminary check for if the Y axis is different for the index that we check. For example, in a grid of 5x5 and a win state of 3, if we check square[5], which will be row 2, col 1, there obviously cannot be a winning state at col minus two, as this will be out of the grid. However, due to the nature of our grid, the square with the index of col minus two will actually be square[3] on row 1, col 4. If this square has the same value, this will cause a false win. To stop this, we put in a check to see if the row of the square we just clicked is the same as the row of the square we're checking. If they're different, we know this particular win state cannot work.
	
	// This checks the X axis for win states.
	for(let i = winCheck; i >= 0; i--){
		let loopCheck = i - winCheck;
		winningSquares = [];
		for(let j = i; j >= loopCheck; j--){
			let colCheck = col - j;
			let indexCheck = calculateIndex(colCheck,row,totalCols);
			if(calculateRow(indexCheck,totalCols) === row){
				if(squares[index] && squares[index] === squares[indexCheck] && squares[indexCheck]){
					winningSquares.push(
						indexCheck
					);
					if(winningSquares.length === winAmount){
						return {
							winner: squares[index],
							winningSquares: winningSquares
						};
					}
				} else {
					// This combination isn't winning because the value of the indexes we're checking are not equal. Empty the winningSquares array and break this loop.
					winningSquares = null;
					break;
				}
			} else {
				// This combination isn't winning because the index we're checking is on another row. Empty the winningSquares array and break this loop.
				winningSquares = null;
				break;
			}
		}
	}
	
	// This checks the Y axis for win states.
	for(let i = winCheck; i >= 0; i--){
		let loopCheck = i - winCheck;
		winningSquares = [];
		for(let j = i; j >= loopCheck; j--){
			let rowCheck = row - j;
			let indexCheck = calculateIndex(col,rowCheck,totalCols);
			if(squares[index] && squares[index] === squares[indexCheck] && squares[indexCheck]){
				winningSquares.push(
					indexCheck
				);
				if(winningSquares.length === winAmount){

					return {
						winner: squares[index],
						winningSquares: winningSquares
					};
				}
			} else {
				// This combination isn't winning because the value of the indexes we're checking are not equal. Empty the winningSquares array and break this loop.
				winningSquares = null;
				break;
			}
		}
	}
	
	// This checks the NW-SE diagonal for win states.
	for(let i = winCheck; i >= 0; i--){
		let loopCheck = i - winCheck;
		winningSquares = [];
		for(let j = i; j >= loopCheck; j--){
			let rowCheck = row - j;
			let colCheck = col - j;
			let indexCheck = calculateIndex(colCheck,rowCheck,totalCols);
			if(calculateRow(indexCheck,totalCols) === rowCheck){
				if(squares[index] && squares[index] === squares[indexCheck] && squares[indexCheck]){
					winningSquares.push(
						indexCheck
					);
					if(winningSquares.length === winAmount){
						return {
							winner: squares[index],
							winningSquares: winningSquares
						};
					}
				} else {
					// This combination isn't winning because the value of the indexes we're checking are not equal. Empty the winningSquares array and break this loop.
					winningSquares = null;
					break;
				}
			} else {
				// This combination isn't winning because the index we're checking is on another row. Empty the winningSquares array and break this loop.
				winningSquares = null;
				break;
			}
		}
	}
	
	// This checks the SW-NE diagonal for win states.
	for(let i = winCheck; i >= 0; i--){
		let loopCheck = i - winCheck;
		winningSquares = [];
		for(let j = i; j >= loopCheck; j--){
			let rowCheck = row - j;
			let colCheck = col + j;
			let indexCheck = calculateIndex(colCheck,rowCheck,totalCols);
			if(calculateRow(indexCheck,totalCols) === rowCheck){
				if(squares[index] && squares[index] === squares[indexCheck] && squares[indexCheck]){
					winningSquares.push(
						indexCheck
					);
					if(winningSquares.length === winAmount){
						return {
							winner: squares[index],
							winningSquares: winningSquares
						};
					}
				} else {
					// This combination isn't winning because the value of the indexes we're checking are not equal. Empty the winningSquares array and break this loop.
					winningSquares = null;
					break;
				}
			} else {
				// This combination isn't winning because the index we're checking is on another row. Empty the winningSquares array and break this loop.
				winningSquares = null;
				break;
			}
		}
	}
	return null;
}

function calculateLocation(location,totalCols) {
	const col = calculateCol(location,totalCols);
	const row = calculateRow(location,totalCols);
	return '(' + col + '), (' + row + ')';
}

function calculateCol(index,totalCols){
	const col = index % totalCols + 1;
	return col;
}

function calculateRow(index,totalCols){
	var row = index / totalCols;
	if(Number.isInteger(row)){
		row = row + 1;
	} else {
		row = Math.ceil(row);
	}
	return row;
}

function calculateIndex(col,row,totalCols){
	const offset = (row - 1) * totalCols;
	const index = col + offset - 1;
	return index;
}